import {
  Companies as CompaniesIcon,
  People as PeopleIcon,
} from '@clearkit/icons';
import { CKSearch } from 'clearkit';
import { Link } from 'react-router-dom';

import LoadingArea from '~/components/LoadingArea';
import { useFancyQuery } from '~/lib/graphql';
import history from '~/lib/history';
import paths from '~/lib/paths';
import {
  readableSegmentType,
  readableSingularSegmentType,
} from '~/lib/segments';

import AddCollectionButton from './AddCollectionButton';
import CollectionsList from './Collections';
import NewAudienceButton from './NewAudienceButton';
import query from './query.graphql';
import SegmentsMenuItem from './SegmentsMenuItem';
import styles from './styles.style';

class SegmentsMenu extends React.Component {
  state = {
    highlightedIndex: -1,
    search: '',
  };

  handleSearch = (search) => {
    this.setState({ search, highlightedIndex: 0 });
  };

  clearSearch = () => {
    this.setState({
      addingCollection: false,
      search: '',
      highlightedIndex: -1,
    });
  };

  clearHighlight = () => {
    this.setState({
      addingCollection: false,
      highlightedIndex: -1,
    });
  };

  allSegments = () => {
    const { segments, collections } = this.props;
    if (!segments || !collections) return [];
    const collectionIds = collections.map((collection) => collection.id);
    return segments.filter((s) => collectionIds.indexOf(s.collectionId) > -1);
  };

  onSearchKeyDown = (event) => {
    let { highlightedIndex } = this.state;

    const numberOfSegments = this.matchingSegments().length;

    if (event.key === 'ArrowDown' && highlightedIndex < numberOfSegments - 1) {
      event.preventDefault();
      if (highlightedIndex === -1) {
        const selectedIndex = this.matchingSegments().indexOf(
          this.matchingSegments().find((s) => s.id === this.props.segmentId),
        );
        highlightedIndex = selectedIndex;
      }
      if (highlightedIndex < numberOfSegments - 1) {
        this.setState({ highlightedIndex: highlightedIndex + 1 });
      }
    } else if (event.key == 'ArrowUp') {
      event.preventDefault();
      if (highlightedIndex === -1) {
        const selectedIndex = this.matchingSegments().indexOf(
          this.matchingSegments().find((s) => s.id === this.props.segmentId),
        );
        highlightedIndex = selectedIndex;
      }
      if (highlightedIndex === 0) {
        this.setState({ highlightedIndex });
      } else {
        this.setState({ highlightedIndex: highlightedIndex - 1 });
      }
    } else if (event.key === 'Enter') {
      const segment = this.matchingSegments()[highlightedIndex];
      if (segment) {
        this.setState({ highlightedIndex: -1, search: '' });
        const href = paths.segment(segment.type, segment.id);
        history.push(href);
      }
    } else if (event.key == 'Escape') {
      this.setState({ highlightedIndex: -1, search: '' });
      event.target.blur();
    }
  };

  matchingSegments = () => {
    let { search = '' } = this.state;
    const segments = this.allSegments();

    return segments.filter((segment) =>
      segment.name?.toLowerCase().includes(search.toLowerCase()),
    );
  };

  renderIcon = (selected) => {
    const { type } = this.props;

    const className = classnames('mr-2 -ml-1', {
      'fill-gradient-br-blue': selected,
    });

    if (type === 'Company') {
      return <CompaniesIcon className={className} />;
    } else {
      return <PeopleIcon className={className} />;
    }
  };

  render() {
    const {
      classes,
      type,
      segmentId,
      collections,
      style,
      loading,
      error,
    } = this.props;

    const firstTimeLoading = loading && !collections;
    const segments = this.allSegments();
    const { search, highlightedIndex } = this.state;

    return (
      <div
        className={classnames(classes.root, 'border-r border-gray-100')}
        style={style}
      >
        <div className={classes.mainTitle}>
          {type === 'Company' ? 'Companies' : 'People'}
        </div>

        <CKSearch
          className="mb-6"
          onChange={this.handleSearch}
          onKeyDown={this.onSearchKeyDown}
          placeholder={`Find ${readableSingularSegmentType(type)} audiences`}
          value={search}
        />

        {!search && (
          <>
            <SegmentsMenuItem
              as={Link}
              className="flex"
              onClick={() => {
                this.clearSearch();
              }}
              selected={!segmentId}
              to={paths.segments(type)}
            >
              {this.renderIcon(!segmentId)}
              <span>All {readableSegmentType(type)}</span>
            </SegmentsMenuItem>
            <div className={classnames('ck-menu-divider mt-2')} />
          </>
        )}
        <LoadingArea
          className={classes.segmentLoadingArea}
          loading={firstTimeLoading}
        >
          <div className={classes.segments}>
            <CollectionsList
              collections={collections}
              error={error}
              highlightedIndex={highlightedIndex}
              loading={firstTimeLoading}
              search={search}
              segmentId={segmentId}
              segments={segments}
              type={type}
            />
          </div>
        </LoadingArea>
        {!search && (
          <>
            <div className={classnames('ck-menu-divider')} />
            <AddCollectionButton type={type} />
          </>
        )}

        <div className={classes.newButtonWrapper}>
          <NewAudienceButton className={classes.newButton} type={type} />
        </div>
      </div>
    );
  }
}

const QuerySegmentsMenu = ({ segmentId, type, classes }) => {
  const { data, loading, error } = useFancyQuery(query, {
    fields: ['segments', 'collections'],
    variables: { collectionType: type, segmentType: type },
    fetchPolicy: 'no-cache',
  });

  return (
    <SegmentsMenu
      classes={classes}
      collections={data?.collections}
      error={error}
      loading={loading}
      segmentId={segmentId}
      segments={data?.segments}
      type={type}
    />
  );
};

export const StyledSegmentsMenu = withStyles(styles)(SegmentsMenu);

QuerySegmentsMenu.propTypes = {
  segmentId: PropTypes.string,
  type: PropTypes.oneOf(['Person', 'Company']).isRequired,
  classes: PropTypes.object,
};

SegmentsMenu.propTypes = {
  error: PropTypes.object,
  style: PropTypes.object,
  segments: PropTypes.array,
  segmentId: PropTypes.string,
  collections: PropTypes.array,
  type: PropTypes.oneOf(['Person', 'Company']).isRequired,
  loading: PropTypes.bool,
  classes: PropTypes.object,
};

SegmentsMenu.defaultProps = {
  type: 'Company',
};

export default withStyles(styles)(QuerySegmentsMenu);

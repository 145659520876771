import moment from 'moment';

export const segmentTypes = {
  company: 'CompanySegment',
  person: 'PersonSegment',
};

// This turns the following arguments into a description for a result set.
// This description currently goes in the header as the subtitle.
export function searchDescription(
  type,
  segmentId,
  count = 0,
  timestamp,
  query,
) {
  if (!type) return '';
  if (type == segmentTypes.company && segmentId === null) return null;
  if (count === null) return 'Waiting for results...';
  const formattedCount = count.toLocaleString();
  let noun =
    count === 1 ? readableSingularSegmentType(type) : readableSegmentType(type);
  noun = query ? `matching ${noun}` : noun;
  let description = `${formattedCount} ${noun}`;
  if (segmentId) description = `${description} in this audience`;
  if (!timestamp) return description;
  let fromNow = moment(timestamp).fromNow();
  if (new Date(timestamp) > new Date()) {
    fromNow = moment().fromNow();
  }
  return `${description}, updated ${fromNow}`;
}

export function legacySegmentType(typename) {
  const segmentTypeMap = {
    [segmentTypes.company]: 'Company',
    [segmentTypes.person]: 'Person',
  };

  return segmentTypeMap[typename];
}

const readableSegmentTypes = {
  [segmentTypes.company]: 'companies',
  [segmentTypes.person]: 'people',
  Person: 'people',
  Company: 'companies',
};

export function readableSegmentType(type) {
  return readableSegmentTypes[type];
}

const readableSingularSegmentTypes = {
  [segmentTypes.company]: 'company',
  [segmentTypes.person]: 'person',
  Person: 'person',
  Company: 'company',
};

export function readableSingularSegmentType(type) {
  return readableSingularSegmentTypes[type];
}

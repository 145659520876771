import { CKLayoutGrid } from 'clearkit';
import { Helmet } from 'react-helmet';

import { HomeBannerConnectCRM } from '~/components/home/Banners';
import { HomeFooter } from '~/components/home/HomeFooter';
import { HomeHeader } from '~/components/home/HomeHeader';
import {
  HomeSectionCompanies,
  HomeSectionDataOverview,
  HomeSectionNotifications,
  HomeSectionOnboarding,
} from '~/components/home/HomeSection';
import {
  HomeSidebar,
  HomeSidebarIntegrations,
  HomeSidebarNews,
  HomeSidebarUsage,
} from '~/components/home/HomeSidebar';
import { useHomeIntegrations } from '~/components/home/hooks/useHomeIntegrations';
import {
  Account,
  AccountNotification,
  AccountNotificationTypeEnum,
  useHomePageQuery,
  User,
} from '~/generated/graphql';
import { useEntitlements } from '~/hooks/account';
import { WhenUsagePreview } from '~/hooks/account/Entitlements/WhenUsagePreview';

export const HomePage = () => {
  const { hasAccessEnrichment, hasAccessEnrichmentHubspot } = useEntitlements();
  const {
    hasCRMConnector,
    isOnboarding,
    loadingAccount,
  } = useHomeIntegrations();

  const { data, loading } = useHomePageQuery();

  const account = data?.account as Account;
  const accountNotifications = notificationsWithFreePlatformEol(
    account,
    data?.accountNotifications as AccountNotification[],
  );

  return (
    <>
      <Helmet>
        <title>Home</title>
      </Helmet>
      <div className="w-full h-full @container/page ck-scrollbar ck-scrollbar--vertical">
        <div className="h-full min-w-0 min-h-0 bg-white @4xl/page:grid grid-cols-[1fr_25rem]">
          <div className="min-h-full ck-scrollbar ck-scrollbar--vertical">
            <CKLayoutGrid
              className="min-w-0 min-h-full p-8 space-y-12"
              gridTemplateRows="1fr auto"
            >
              <div className="min-h-0 space-y-12">
                <HomeHeader
                  account={account}
                  hasAccountIssue={!!data?.accountNotifications.length}
                  isOnboarding={isOnboarding}
                  user={data?.viewer.user as User}
                />
                <HomeSectionNotifications
                  accountConnectors={
                    data?.accountConnectors as Account['connectors']
                  }
                  notifications={accountNotifications}
                />
                {hasAccessEnrichmentHubspot ? <HomeSectionCompanies /> : null}

                {!hasAccessEnrichmentHubspot && !loadingAccount ? (
                  <div className="relative space-y-12">
                    {!hasCRMConnector && hasAccessEnrichment ? (
                      <HomeBannerConnectCRM />
                    ) : null}
                    <HomeSectionDataOverview />
                  </div>
                ) : null}

                <HomeSectionOnboarding />
              </div>
              <HomeFooter />
            </CKLayoutGrid>
          </div>
          <HomeSidebar>
            {!loading ? (
              <>
                <WhenUsagePreview>
                  <HomeSidebarUsage />
                </WhenUsagePreview>
                <HomeSidebarIntegrations />
                <HomeSidebarNews news={data?.news} />
              </>
            ) : null}
          </HomeSidebar>
        </div>
      </div>
    </>
  );
};

const notificationsWithFreePlatformEol = (
  account?: Account,
  accountNotifications?: AccountNotification[],
) => {
  if (!account) {
    return [];
  }

  const notifications = accountNotifications || [];
  const free_platform_plans = [
    'free_tier',
    'hubspot_free_tier',
    'hubspot_platform_free',
  ];
  if (
    account.subscriptions.some((subscription) =>
      free_platform_plans.includes(subscription.planId),
    )
  ) {
    return [
      ...notifications,
      {
        id: '',
        createdAt: new Date(),
        notificationType: AccountNotificationTypeEnum.PlatformFreeToolsEol,
        resolved: false,
        message:
          "The free versions of Clearbit's Platform will be discontinued on April 30, 2025. See our blog post for more information.",
      },
    ];
  }
  return notifications;
};
